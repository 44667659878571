import { zonedTimeToUtc } from 'date-fns-tz';

const DEFAULT_MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const DEFAULT_DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getTimeAmPm = (date: Date) => {
  return date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
};

export const getMonthName = (date: Date, translatedMonthNames = DEFAULT_MONTH_NAMES) => {
  return translatedMonthNames[date.getMonth()];
};

export const getDayName = (date: Date, translatedDaysOfWeek = DEFAULT_DAYS_OF_WEEK) => {
  return translatedDaysOfWeek[date.getDay()];
};

export const getLocaleDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getExtractDateFields = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return { year, month: parseInt(month), day: parseInt(day) };
};

export const getLocaleTimeString = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getDaysPassed = (startTime: Date, endTime: Date): number => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const startDay = Math.floor(startTime.getTime() / millisecondsPerDay);
  const currentDay = Math.floor(endTime.getTime() / millisecondsPerDay);
  return currentDay - startDay;
};

export const getDateInSpecificTimezone = (date: Date, timezone: string) => {
  return zonedTimeToUtc(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`, timezone);
};
