import React, { useMemo, useState } from 'react';
import { Flex, useDisclosure } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { GuestTravelMapComponentFragment } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useTravelMapData } from './hooks/useTravelMapData';
import { BookingAssistantCta } from './components/BookingAssistantCta';
import { AccommodationsTileV2, AccommodationsTileV2Skeleton } from './components/AccommodationsV2/AccommodationsTileV2';
import { AccommodationsTileV2Favorite, AccommodationsTileV2FavoriteSkeleton } from './components/AccommodationsV2/AccommodationsTileV2Favorite';
import { AccommodationsDialog } from './components/AccommodationsDialog';
import { AccommodationCombined, AccommodationCombinedType } from './components/Accommodations/Accommodation.types';
import { AccommodationsMapV2, AccommodationsMapV2Skeleton } from './components/AccommodationsMapV2';
import { useCustomPageTelemetry } from '../../Custom.telemetry';
import { JoyInteractableMapProvider } from '@shared/components/JoyInteractableMap/JoyInteractableMap.provider';
import { RedirectWithSafeLink } from '@apps/guest/components/RedirectWithSafeLink';
import { useFeatureValue } from '@shared/core/featureFlags';
import { withWindow } from '@shared/utils/withWindow';
import { useEventInfo } from '@shared/utils/eventInfo';
import { distanceInMiles } from '@shared/utils/distanceInMiles';

interface TravelMapProps {
  pageId: string;
  eventId: string;
  pageSlug: string;
  eventHandle: string;
  pageTitle?: string;
  subTitle?: string | null;
  isBrannanLayout?: boolean;
  travelMapComponentData: GuestTravelMapComponentFragment;
}

export const TravelMapV2: React.FC<TravelMapProps> = ({ pageSlug, eventId, eventHandle, pageTitle, isBrannanLayout, travelMapComponentData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [safeLink, setSafeLink] = useState<string | undefined>();
  const [selectedAccommodation, setSelectedAccommodation] = useState<AccommodationCombined | undefined>(undefined);
  const [locationBeingHovered, setLocationBeingHovered] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isGridView = isBrannanLayout && !isMobile;

  const { loading: accommodationsGuestHotelCheckoutLoading, value: accommodationsGuestHotelCheckout } = useFeatureValue('accommodationsGuestHotelCheckoutEnabled');

  const {
    loading,
    showDefaultData,
    destination,
    eventPhoto,
    reservedRoomBlocks: reservedRoomBlocksOriginal,
    pointsOnMap,
    venues,
    sortedHotelList,
    eventTimezone
  } = useTravelMapData(eventId, travelMapComponentData);

  const favorteRoomBlock = reservedRoomBlocksOriginal.find(roomBlock => roomBlock.isFavorite);
  const { primaryRoomBlock, reservedRoomBlocks } = useMemo(() => {
    const primaryRoomBlock = favorteRoomBlock ?? reservedRoomBlocksOriginal.length > 0 ? reservedRoomBlocksOriginal[0] : null;
    const reservedRoomBlocks = favorteRoomBlock ?? reservedRoomBlocksOriginal.length > 0 ? reservedRoomBlocksOriginal.slice(1) : [];
    return {
      primaryRoomBlock,
      reservedRoomBlocks
    };
  }, [favorteRoomBlock, reservedRoomBlocksOriginal]);

  const { eventInfo } = useEventInfo();
  const telemetry = useCustomPageTelemetry();
  const onSelectHotel = useEventCallback((id: string, label: 'viewDetail' | 'viewPreview') => {
    const reservedRoomBlock = reservedRoomBlocksOriginal.map((item, index) => ({ ...item, index })).find(reservedRoomBlock => reservedRoomBlock.id === id);
    if (reservedRoomBlock) {
      const telemetryHotelInfo = {
        hotelName: reservedRoomBlock.displayName || '',
        isHotelPreferred: reservedRoomBlock.isFavorite || false,
        hotelPrice: reservedRoomBlock.pricePerNight || undefined,
        hotelStrikeThroughPrice: reservedRoomBlock.strikeoutPricePerNight || undefined,
        type: reservedRoomBlock.type
      };
      telemetry.openAccommodationsDialog('accommodations', telemetryHotelInfo, label);
      if (
        reservedRoomBlock.type !== AccommodationCombinedType.Roomblock &&
        !accommodationsGuestHotelCheckoutLoading &&
        accommodationsGuestHotelCheckout === 'on' &&
        reservedRoomBlock.isFromAccommodationCatalog &&
        !reservedRoomBlock.customUrl
      ) {
        const venue = venues[0];
        const location = { lat: venue?.latitude || eventInfo?.locationInfo?.latitude, lng: venue?.longitude || eventInfo?.locationInfo?.longitude };
        const haveDistanceToVenue = Boolean(reservedRoomBlock.latitude && reservedRoomBlock.longitude && location?.lat && location.lng);
        const venueName = venue?.name ?? eventInfo?.locationInfo?.name;
        const distance = distanceInMiles(reservedRoomBlock.latitude || 0, reservedRoomBlock.longitude || 0, location?.lat || 0, location?.lng || 0).toFixed(1);
        const hotel = {
          eventHandle,
          accommodationPlaceId: reservedRoomBlock.id,
          hotelName: reservedRoomBlock.displayName || '',
          venueName,
          ...(haveDistanceToVenue && { milesFromVenue: distance })
        };
        telemetry.viewHotelPDPPage(hotel);
        withWindow(global => global.open(`/${eventHandle}/accommodations/${id}`, '_blank'));
      } else {
        setSelectedAccommodation(reservedRoomBlock);
        onOpen();
      }
    }
  });

  // Show map if have at least one venue or hotel with latitude or longitude
  const shouldShowMap = useMemo(
    () =>
      pointsOnMap.find(pointOnMap => (pointOnMap.latitude && pointOnMap.latitude !== 0) || (pointOnMap.longitude && pointOnMap.longitude !== 0)) ||
      reservedRoomBlocksOriginal?.find(
        reservedRoomBlock => (reservedRoomBlock.latitude && reservedRoomBlock.latitude !== 0) || (reservedRoomBlock.longitude && reservedRoomBlock.longitude !== 0)
      ),
    [pointsOnMap, reservedRoomBlocksOriginal]
  );

  const renderMap = useMemo(() => {
    return (
      <SkeletonGroup isReady={!loading} placeholder={<AccommodationsMapV2Skeleton isBrannanLayout={isBrannanLayout} />} width="100%" paddingX={6}>
        {shouldShowMap && (
          <AccommodationsMapV2
            showFullScreenMap={false}
            pointsOnMap={pointsOnMap}
            accommodationsCombined={reservedRoomBlocksOriginal ?? []}
            pageTitle={pageTitle}
            onSelectHotel={id => onSelectHotel(id, 'viewPreview')}
            isBrannanLayout={isBrannanLayout}
            locationBeingHovered={locationBeingHovered}
            hideExploreCta={!isBrannanLayout}
            hideDirection
          />
        )}
      </SkeletonGroup>
    );
  }, [isBrannanLayout, loading, locationBeingHovered, onSelectHotel, pageTitle, pointsOnMap, reservedRoomBlocksOriginal, shouldShowMap]);

  return (
    <JoyInteractableMapProvider>
      <Flex flexDirection="column" alignItems="center" width="100%" rowGap={{ _: 7, sm2: isBrannanLayout ? 7 : 8 }}>
        <Flex flexDirection="column" alignItems="center" width="100%" rowGap={{ _: 0, sm2: isBrannanLayout ? 7 : 0 }} paddingTop={{ _: 8, sm2: isBrannanLayout ? 5 : 0 }}>
          <Flex width="100%" paddingX={{ xxs: isBrannanLayout ? 7 : 0, xs: isBrannanLayout ? 7 : 0 }}>
            <Flex width="100%" display={isGridView ? 'grid' : 'flex'} flexDirection="column" gridTemplateColumns="1fr 2fr" gap={{ _: 7, sm2: isBrannanLayout ? 0 : 7 }}>
              {isGridView && renderMap}
              <SkeletonGroup marginY={{ sm2: isBrannanLayout ? -6 : 0 }} isReady={!loading} placeholder={<AccommodationsTileV2FavoriteSkeleton />}>
                {primaryRoomBlock && (
                  <AccommodationsTileV2Favorite
                    {...primaryRoomBlock}
                    isFirst={true}
                    isLast={false}
                    locationInfo={destination}
                    eventHandle={eventHandle}
                    showDefaultData={showDefaultData}
                    onSelectHotel={id => onSelectHotel(id, 'viewPreview')}
                    setLocationBeingHovered={setLocationBeingHovered}
                  />
                )}
              </SkeletonGroup>
            </Flex>
          </Flex>
          <Flex width="100%" paddingX={{ xxs: isBrannanLayout ? 7 : 0, xs: isBrannanLayout ? 7 : 0 }}>
            <Flex width="100%" flexDirection="column" gap={7}>
              <SkeletonGroup
                isReady={!loading}
                placeholder={
                  <>
                    <AccommodationsTileV2Skeleton />
                    <AccommodationsTileV2Skeleton />
                    <AccommodationsTileV2Skeleton />
                  </>
                }
                width="100%"
                display={isGridView ? 'grid' : 'flex'}
                flexDirection="column"
                gridTemplateColumns="repeat(3, minmax(0px, 1fr))"
              >
                {reservedRoomBlocks && reservedRoomBlocks?.length > 0 && (
                  <>
                    {reservedRoomBlocks.map((reservedRoomBlock, idx) => {
                      return (
                        <AccommodationsTileV2
                          {...reservedRoomBlock}
                          key={`${reservedRoomBlock.id}-${idx}`}
                          idx={idx + 1}
                          locationInfo={destination}
                          eventHandle={eventHandle}
                          showDefaultData={showDefaultData}
                          onSelectHotel={id => onSelectHotel(id, 'viewDetail')}
                          setLocationBeingHovered={setLocationBeingHovered}
                        />
                      );
                    })}
                  </>
                )}
              </SkeletonGroup>
              {!isGridView && renderMap}
            </Flex>
          </Flex>
        </Flex>
        {!travelMapComponentData.hideExploreCTA && (
          <BookingAssistantCta accommodations={sortedHotelList} venue={venues[0] || null} pageSlug={pageSlug} eventTimezone={eventTimezone} />
        )}
        <RedirectWithSafeLink
          href={safeLink}
          skipTelemetry
          onClose={() => {
            setSafeLink(undefined);
          }}
        />
        {selectedAccommodation && (
          <AccommodationsDialog
            isOpen={isOpen}
            onClose={onClose}
            accommodation={selectedAccommodation}
            showDefaultData={showDefaultData}
            shouldShowMap={!!shouldShowMap}
            pointsOnMap={pointsOnMap}
            pageTitle={pageTitle}
            locationInfo={destination}
            eventHandle={eventHandle}
            eventPhoto={eventPhoto}
            setSafeLink={setSafeLink}
          />
        )}
      </Flex>
    </JoyInteractableMapProvider>
  );
};
