import { cardDieCutToShapeConfig } from '@apps/card/Card.utils';
import { Box } from '@withjoy/joykit';
import React, { FC, Key, ReactElement, ReactNode } from 'react';
import { DraftFunction, Updater } from 'use-immer';
import { CardDieCut, ThemeJson } from '../CardCustomizer.types';
import { SurfaceState } from '../steps/CardDesign/useSurface';
import { ImageLayer } from './ImageLayer';
import { LayerData } from './Layer.types';
import { QRCodeLayer } from './QRCodeLayer';
import { TextLayer } from './TextLayer';
import { RectangleLayer } from './RectangleLayer';
import { FoilLayer } from './FoilLayer';
import { DEFAULT_CUT_MARGIN_INCHES, PPI } from '../steps/CardDesign/config';
import { StationeryDraftFormat } from '@graphql/generated';

export interface PageProps {
  key?: Key;
  width: number;
  height: number;
  page: ThemeJson['card']['front'] | ThemeJson['card']['back'];
  shape?: CardDieCut;
  loader?: ReactElement;
  loading?: boolean;
  format?: StationeryDraftFormat;
}

export interface PageEditor {
  isActive: (layerIndex: number) => boolean;
  isEditing: (layerIndex: number) => boolean;
  updateLayer: (layerIndex: number, df: DraftFunction<LayerData>) => void;
  Selector: FC<{ layer: LayerData; layerIndex: number }>;
  updateSurface: Updater<SurfaceState>;
}

const PassthroughSelector = (props: { layer: LayerData; layerIndex: number; children: ReactNode }) => {
  return <>{props.children}</>;
};

export const Page = ({ key, width, height, shape, page, editor, loader, loading, format }: PageProps & { editor?: PageEditor }) => {
  const Selector = editor?.Selector ?? PassthroughSelector;
  return (
    <Box
      key={key}
      backgroundColor={page.fill}
      clipPath={cardDieCutToShapeConfig(shape ?? CardDieCut.rectangle).clipPath}
      width={(page.dimensions?.width ?? width) * PPI}
      height={(page.dimensions?.height ?? height) * PPI}
      overflow="clip"
      position="relative"
      transition="clip-path 0.3s"
    >
      {page.layers.map((layer, i) => {
        return (
          <Selector key={i} layer={layer} layerIndex={i}>
            {(() => {
              const lType = layer.type;
              const isActive = layer.editable && (editor?.isActive(i) ?? false);
              if (lType === 'text') return <TextLayer format={format} layer={layer} editor={editor} layerIndex={i} />;
              if (lType === 'image') return <ImageLayer loader={loader} loading={loading} layer={layer} editor={editor} layerIndex={i} />;
              if (lType === 'qrcode') return <QRCodeLayer format={format} layer={layer} editor={editor} isActive={isActive} />;
              if (lType === 'rectangle') return <RectangleLayer layer={layer} />;
              if (lType === 'foil') return <FoilLayer layer={layer} pageDimensions={page.dimensions ?? { width, height, cutMargin: DEFAULT_CUT_MARGIN_INCHES }} />;
              throw new Error(`Unknown layer type: ${lType} found in card template`);
            })()}
          </Selector>
        );
      })}
    </Box>
  );
};
