import { useEventDesignTheming } from '@apps/guest/routes/GuestSite/GuestSite.utils';
import { useEventWebsiteGuestQuery, EventDesignPurpose, DesignLayoutType } from '@graphql/generated';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import React from 'react';
import { FontPackProvider } from '../../layouts/FontPackProvider/FontPackProvider';
import EventMenu from '../../layouts/LayoutAloha/components/EventMenu/EventMenu';
import { LayoutProvider } from '../../layouts/LayoutProvider';
import { UnlockDialogProvider } from '../UnlockDialog/UnlockDialogProvider';

export const StandAloneAlohaMenu = ({ eventHandle }: { eventHandle: string }) => {
  const { data, loading, error } = useEventWebsiteGuestQuery({
    variables: {
      eventHandle,
      eventDesignPurpose: EventDesignPurpose.live,
      isAdminDashboard: false
    },
    batchMode: 'fast',
    ssr: true,
    fetchPolicy: 'cache-first'
  });
  const { theme, loadingFonts } = useEventDesignTheming({ eventDesign: data?.eventByName?.eventDesign });
  if (loading || loadingFonts || error || !data?.eventByName) {
    return null;
  }
  return (
    <JoyKitThemeProvider theme={theme}>
      <LayoutProvider layout={DesignLayoutType.aloha}>
        <FontPackProvider eventDesign={data.eventByName.eventDesign}>
          <UnlockDialogProvider>
            <EventMenu
              eventHandle={eventHandle}
              title={data.eventByName.info.eventDisplayName}
              eventProps={{
                eventId: data.eventByName.id,
                layoutType: DesignLayoutType.aloha,
                dateInMilliseconds: data.eventByName.info.dateV0918?.milliseconds || 0,
                pages: data.eventByName.pages
              }}
            />
          </UnlockDialogProvider>
        </FontPackProvider>
      </LayoutProvider>
    </JoyKitThemeProvider>
  );
};
