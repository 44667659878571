import { getExtractDateFields, getLocaleDateString } from '@shared/utils/dateHelpers';
import { addDays, subDays, isBefore, isValid, isSameMonth, differenceInCalendarDays } from 'date-fns';

const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

export const useDateFromDatestring = (dateString?: string) => {
  if (!dateString) {
    return null;
  }
  return new Date(createDateFromUnformattedString(dateString).toLocaleDateString('en-US'));
};

export const useCalculatedCheckIn = (dateString?: string) => {
  if (!dateString || isBefore(new Date(dateString), new Date())) {
    return null;
  }
  return subDays(new Date(dateString), 1);
};

export const useCalculatedCheckOut = (dateString?: string) => {
  if (!dateString || isBefore(new Date(dateString), new Date())) {
    return null;
  }
  return addDays(new Date(dateString), 1);
};

export const validateCheckInCheckOut = (dateCheckInCheckOut: Date) => {
  const today = new Date();
  if (isBefore(dateCheckInCheckOut, today)) {
    return null;
  }
  return dateCheckInCheckOut;
};

export const createDateFromUnformattedString = (dateString: string): Date => {
  if (dateOnlyRegex.test(dateString)) {
    return new Date(`${dateString}T00:00:00`);
  }

  if (isValid(new Date(dateString))) {
    return new Date(dateString);
  }

  return new Date();
};

export const isSameMonthCheckInCheckOut = (inDate: string, outDate: string) => {
  if (isSameMonth(new Date(inDate), new Date(outDate))) {
    return true;
  }
  return false;
};

export const isCutOffDateNinteyOrFewerDaysAway = (dateStringCutOffDate: string) => {
  const today = new Date();
  if (differenceInCalendarDays(new Date(dateStringCutOffDate), today) <= 90) {
    return true;
  }
  return false;
};

type DateRangeType = { dateString: string; extractedDateFields: { year: number; month: number; day: number } };

export const getDateRange = (date: string | null | undefined): { startDate: DateRangeType; endDate: DateRangeType } => {
  const today = new Date();
  const finalizedDateObj = date ? new Date(date) : null;

  let startDate: Date;
  let endDate: Date;

  if (finalizedDateObj && finalizedDateObj > today) {
    // If date is in the future
    startDate = new Date(finalizedDateObj);
    startDate.setDate(finalizedDateObj.getDate() - 1); // 1 day before
    endDate = new Date(finalizedDateObj);
    endDate.setDate(finalizedDateObj.getDate() + 1); // 1 day after
  } else {
    // If date is in the past or doesn't exist
    startDate = new Date(today);
    endDate = new Date(today);
    endDate.setDate(today.getDate() + 2); // 2 days after today
  }

  return {
    startDate: {
      dateString: getLocaleDateString(startDate),
      extractedDateFields: getExtractDateFields(startDate)
    },
    endDate: {
      dateString: getLocaleDateString(endDate),
      extractedDateFields: getExtractDateFields(endDate)
    }
  };
};
