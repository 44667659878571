import { Box } from '@withjoy/joykit';
import React from 'react';
import { PageDimensions } from '../CardCustomizer.types';
import { MEDIA_SERVICE, PPI } from '../steps/CardDesign/config';
import { FoilLayerData } from './Layer.types';
import { absolutePhotoUrl } from './ImageLayer';

export const FoilLayer = (props: { layer: FoilLayerData; pageDimensions: Pick<PageDimensions, 'width' | 'height' | 'cutMargin'> }) => {
  const { layer, pageDimensions } = props;
  const { src, colorId } = layer.foilData;
  const cutMargin = pageDimensions.cutMargin * PPI;

  const url = absolutePhotoUrl(src);

  const filter = (() => {
    if (colorId === 'ROSEGOLD') return 'hue-rotate(-35deg) saturate(0.8)';
    if (colorId === 'SILVER') return 'saturate(0.1)';
    return undefined;
  })();

  return (
    <Box
      style={{
        position: 'absolute',
        left: -cutMargin,
        top: -cutMargin,
        width: pageDimensions.width * PPI + cutMargin * 2,
        height: pageDimensions.height * PPI + cutMargin * 2,
        overflow: 'hidden', // Safari not responding to 'clip'

        // See ImageLayer for file size comments.
        backgroundImage: `url(${url.startsWith(MEDIA_SERVICE) ? `${url}?rendition=medium` : url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        filter
      }}
    />
  );
};
